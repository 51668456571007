<template>

  <element>
    <div class="builder-wrapper">
      <!-- builder sidebar starts -->
      <BuilderSidebar />
      <!-- builder sidebar ends -->
      <div class="main-container" :class="isSidebarOpen ? 'sidebar-open' : 'sidebar-close'">
        <!-- builder topbar starts -->
        <BuilderTopbar />
        <!-- builder topbar ends -->
        <br>

        <b-row>
          <b-col cols="4">
            <b-form-group label="name" label-for="name" style="padding:10px">
              <template #label>{{ $t('labels.name') }}</template>
              <b-form-input id="name" type="text" v-model="name" />

            </b-form-group>
          </b-col>
        </b-row>

        <!-- builder Main starts -->
        <BuilderMain />
        <!-- builder Main ends -->
        <br>
        <div class="text-center">
          <b-overlay :show="loadingSave" rounded="sm" v-if="!isEdit">
            <b-button variant="primary" class="mr-2" @click="getObject()">
              {{$t('buttons.save') }}
            </b-button>
          </b-overlay>
          <b-overlay :show="loadingSave" rounded="sm" v-if="isEdit">
            <b-button variant="primary" class="mr-2" @click="updateFooter()">
              {{$t('buttons.update') }}
            </b-button>
          </b-overlay>
          <!-- <b-button
        variant="primary"
        class="mr-2"
        @click="getObject()"
      >
        Previsualización
      </b-button> -->
          <b-button type="button" variant="outline-secondary" :to="$router.resolve({ name: 'apps-footers-list' }).href">
            {{ $t('buttons.cancel') }}
          </b-button>
        </div>
        <br>
        <PreviewMain :previewFooter="previewFooter" />
      </div>
    </div>
    <br>
  </element>

</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BCol, BRow, BFormInput, BLink, BFormGroup, BOverlay
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import footertoreModule from '../footerStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import BuilderTopbar from "@/builder_components/builder/BuilderTopbar.vue";
import BuilderSidebar from "@/builder_components/builder/BuilderSidebar.vue";
import BuilderMain from "@/builder_components/builder/BuilderMain.vue";
import PreviewMain from "@/builder_components/preview/PreviewMain.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BuilderSidebar,
    BuilderTopbar,
    BuilderMain,
    BOverlay,
    PreviewMain
  },
  setup() {
    const headerData = ref(null)

    const APP_STORE_MODULE_NAME = 'moduleFooter'
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, footertoreModule)
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
  },

  data() {
    return {
      /* require, */
      footer: undefined,
      name: '',
      loadingSave: false,
      dbSections: [],
      id: undefined,
      isEdit:false
    }
  },

  computed: {
    ...mapGetters(["isSidebarOpen"]),
    previewFooter() {
      return this.$store.getters.getFooter
    },
  },
  methods: {
    async loadDataModeEdit(){
      await store.dispatch('moduleFooter/fetchFooter', { id: router.currentRoute.params.id })
      .then(response => {
        const dbSections = response.data.sections
        this.name = response.data.name
        this.id = response.data._id
        this.$store.commit('setSections', dbSections)
        this.isEdit = true        
      }).catch(error => {
        if (error.response.status === 404) {
        }
      })
    },
    async getObject() {
      try {
        this.loadingSave = true
        const payload = {
          name: this.name,
          description: '',
          sections: this.$store.getters.getFooter
        }
        // this.$store.dispatch("getObject", {name:this.name});
        await this.$store.dispatch("moduleFooter/addFooter", payload);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('new_footer_added'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'apps-footers-list' })

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingSave = false
      }
    },

    async updateFooter(){
      try {
        this.loadingSave = true
        const payload = {
          id: this.id,
          footerData: {
            name: this.name,
            description: '',
            sections: this.$store.getters.getFooter
          }
        }
        // this.$store.dispatch("getObject", {name:this.name});
        await this.$store.dispatch("moduleFooter/updateFooter", payload);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('footer_updated'),
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'apps-footers-list' })

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingSave = false
      }
    }
  },
  created() {
    if(router.currentRoute.params.id != null){
      this.loadDataModeEdit()
    }
  }
}
</script>


<style lang="scss" scoped>
.builder-wrapper {
  height: 100%;
  display: flex;
  justify-content: left;
  margin-top: 25px;

  .main-container {
    background: #ffffff;
    width: 100%;
    margin: 0;
    transition: margin-left 0.5s;

    &.sidebar-open {
      margin-left: 393px;
    }
  }
}
</style>
